<template>
  <div class="vg_wrapper vd_top" v-loading="loadingFlag">
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="prodForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('prodForm')"
      />
      <el-button
        type="primary"
        size="small"
        class="vd_export"
        @click="buttonRefresh()"
        v-if="isShow"
        >刷新</el-button
      >
      <el-button @click="drawer = true" v-if="specialDataFlag && isShow && prodForm.status === 2" size="small" type="warning" class="vd_export1">分享账套</el-button>
      <!-- <el-button type="primary" :disabled="isDisable" size="small" class="vd_exports" @click="takee()" v-if="isShow">生成产品信息</el-button> -->
    </div>
    <el-form
      ref="prodForm"
      :model="prodForm"
      label-width="120px"
      size="mini"
      :disabled="isShow"
      :rules="rules"
    >
      <el-row>
        <el-col :md="8">
          <el-form-item label="产品图片">
            <el-upload
              disabled
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <!-- <img v-if="prodForm.imge_id" :src="prodForm.imge_url" class="avatar"/> -->
              <imgPopover
                v-if="prodForm.imge_id"
                :imgeUrl="prodForm.imge_url"
                :imgeUrlO="prodForm.imge_urlO"
              ></imgPopover>
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                :class="{ vd_cussor: isShow }"
              ></i>
            </el-upload>
            <!-- <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span> -->
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row>
            <el-col :md="12">
              <el-form-item label="产品编号" prop="prod_no">
                <el-input
                  disabled
                  v-model="prodForm.prod_no"
                  @input="prodForm.prod_no = helper.keepEngNum2(prodForm.prod_no)"
                  maxlength="10"
                  show-word-limit
                  placeholder="请填写产品编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户货号" prop="prod_cust_no">
                <el-input
                  :disabled="!specialDataFlag"
                  v-model="prodForm.prod_cust_no"
                  @input="
                    prodForm.prod_cust_no = helper.keepEngNum2(
                      prodForm.prod_cust_no
                    )
                  "
                  show-word-limit
                  maxlength="30"
                  placeholder="请填写客户货号"
                >
                  <!-- <el-link slot="append" type="primary" @click="notice = true;" :disabled="isShow">导入</el-link> -->
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="16">
          <el-row>
            <el-col :md="12">
              <el-form-item label="产品名称" prop="prod_name">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_name"
                  maxlength="30"
                  show-word-limit
                  placeholder="请填写产品名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="产品尺寸" prop="prod_spec">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_spec"
                  maxlength="30"
                  show-word-limit
                  placeholder="请填写产品尺寸"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <!-- </el-row>
      <el-row> -->
        <!-- <el-col :md="8">
          <el-form-item label="打样数量" prop="prod_num" required>
            <el-input
              v-model="prodForm.prod_num"
              maxlength="10"
              @input="prodForm.prod_num = helper.keepTNum1(prodForm.prod_num)"
              show-word-limit
              placeholder="请填写打样数量"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="8">
          <el-form-item label="开版师" prop="prod_stff_id">
            <el-select
              v-model="prodForm.prod_stff_id"
              placeholder="请选择开版师"
              clearable
            >
              <el-option
                v-for="item in stffType"
                :key="item.stff_id"
                :label="item.stff_name"
                :value="item.stff_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="8">
          <el-form-item label="产品间报价" prop="prod_price">
            <el-input
              v-model="prodForm.prod_price"
              @input="
                prodForm.prod_price = helper.keepTNum1(prodForm.prod_price)
              "
              @blur="
                prodForm.prod_price = helper.calcPrice(
                  prodForm.prod_price,
                  4,
                  1000000
                )
              "
              maxlength="11"
              show-word-limit
              placeholder="请填写产品间报价"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <!-- </el-row>
      <el-row> -->
        <!-- <el-col :md="8">
          <el-form-item label="实际交样日期" prop="prod_deil_time">
            <el-date-picker
              v-model="prodForm.prod_deil_time"
              type="date"
              placeholder="选择交样日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="产品类型" prop="prod_type">
            <el-select
              v-model="prodForm.prod_type"
              placeholder="请选择产品类型"
              clearable
            >
              <el-option
                v-for="item in prodType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购部核价" prop="prod_verify_price">
            <el-input
              v-model="prodForm.prod_verify_price"
              @input="
                prodForm.prod_verify_price = helper.keepTNum1(
                  prodForm.prod_verify_price
                )
              "
              @blur="
                prodForm.prod_verify_price = helper.calcPrice(
                  prodForm.prod_verify_price,
                  4,
                  1000000
                )
              "
              maxlength="11"
              show-word-limit
              placeholder="请填写采购部核价"
            ></el-input>
          </el-form-item>
        </el-col> -->
        
      <!-- </el-row>
      <el-row> -->
        <el-col :md="16">
          <el-row>
            <el-col :md="12">
              <el-form-item label="纸箱长度" prop="prod_box_l">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_box_l"
                  @input="
                    prodForm.prod_box_l = helper.keepTNum1(prodForm.prod_box_l)
                  "
                  @blur="
                    prodForm.prod_box_l = helper.retain1(
                      prodForm.prod_box_l,
                      2,
                      1000000
                    )
                  "
                  maxlength="9"
                  show-word-limit
                  placeholder="请填写纸箱长度"
                >
                  <template slot="append">厘米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="纸箱宽度" prop="prod_box_w">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_box_w"
                  @input="
                    prodForm.prod_box_w = helper.keepTNum1(prodForm.prod_box_w)
                  "
                  @blur="
                    prodForm.prod_box_w = helper.retain1(
                      prodForm.prod_box_w,
                      2,
                      1000000
                    )
                  "
                  maxlength="9"
                  show-word-limit
                  placeholder="请填写纸箱宽度"
                >
                  <template slot="append">厘米</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="16">
          <el-row>
          <el-col :md="12">
            <el-form-item label="纸箱高度" prop="prod_box_h">
              <el-input
              :disabled="!specialDataFlag"
                v-model="prodForm.prod_box_h"
                @input="
                  prodForm.prod_box_h = helper.keepTNum1(prodForm.prod_box_h)
                "
                @blur="
                  prodForm.prod_box_h = helper.retain1(
                    prodForm.prod_box_h,
                    2,
                    1000000
                  )
                "
                maxlength="9"
                show-word-limit
                placeholder="请填写纸箱高度"
              >
                <template slot="append">厘米</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="塑料袋长度" prop="prod_bag_l">
              <el-input
              :disabled="!specialDataFlag"
                v-model="prodForm.prod_bag_l"
                @input="
                  prodForm.prod_bag_l = helper.keepTNum1(prodForm.prod_bag_l)
                "
                @blur="
                  prodForm.prod_bag_l = helper.retain1(
                    prodForm.prod_bag_l,
                    2,
                    1000000
                  )
                "
                maxlength="9"
                show-word-limit
                placeholder="请填写塑料袋长度"
              >
                <template slot="append">厘米</template>
              </el-input>
            </el-form-item>
          </el-col>
          </el-row>
        </el-col>
        <el-col :md="16">
          <el-row>
            <el-col :md="12">
              <el-form-item label="塑料袋宽度" prop="prod_bag_w">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_bag_w"
                  @input="
                    prodForm.prod_bag_w = helper.keepTNum1(prodForm.prod_bag_w)
                  "
                  @blur="
                    prodForm.prod_bag_w = helper.retain1(
                      prodForm.prod_bag_w,
                      2,
                      1000000
                    )
                  "
                  maxlength="9"
                  show-word-limit
                  placeholder="请填写塑料袋宽度"
                >
                  <template slot="append">厘米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="装箱数" prop="prod_qpc">
                <el-input
                :disabled="!specialDataFlag"
                  v-model="prodForm.prod_qpc"
                  @input="prodForm.prod_qpc = helper.keepTNum(prodForm.prod_qpc)"
                  maxlength="10"
                  show-word-limit
                  placeholder="请填写装箱数"
                >
                  <template slot="append">箱</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
      </el-col>
      <el-col :md="24">
        <el-row>
          <el-col :md="8">
            <div style="visibility: hidden;">11</div>
          </el-col>
          <el-col :md="8">
            <el-form-item label="装袋数" prop="prod_qpb">
              <el-input
              :disabled="!specialDataFlag"
                v-model="prodForm.prod_qpb"
                @input="prodForm.prod_qpb = helper.keepTNum(prodForm.prod_qpb)"
                maxlength="9"
                show-word-limit
                placeholder="请填写装袋数"
              >
                <template slot="append">个</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="8">
            <el-form-item label="最小起订量" prop="prod_moq">
              <el-input
                v-model="prodForm.prod_moq"
                @input="prodForm.prod_moq = helper.keepTNum1(prodForm.prod_moq)"
                maxlength="9"
                show-word-limit
                placeholder="请填写最小起订量"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :md="8">
            <el-form-item label="运费单价" prop="prod_freight">
              <el-input
                v-model="prodForm.prod_freight"
                @input="
                  prodForm.prod_freight = helper.keepTNum1(prodForm.prod_freight)
                "
                @blur="
                  prodForm.prod_freight = helper.calcPrice(
                    prodForm.prod_freight,
                    4,
                    1000000
                  )
                "
                maxlength="11"
                show-word-limit
                placeholder="请填写运费单价"
              ></el-input>
            </el-form-item>
          </el-col> -->
        <!-- </el-row>
        <el-row> -->
          <el-col :md="8">
            <el-form-item label="产品重量" prop="prod_weight">
              <el-input
              :disabled="!specialDataFlag"
                v-model="prodForm.prod_weight"
                @input="
                  prodForm.prod_weight = helper.keepTNum1(prodForm.prod_weight)
                "
                @blur="
                  prodForm.prod_weight = helper.retain1(
                    prodForm.prod_weight,
                    2,
                    1000000
                  )
                "
                maxlength="9"
                show-word-limit
                placeholder="请填写产品重量"
              >
                <template slot="append">克</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
      <!-- <el-col :md="16"> -->
    <el-row>
      <el-col :md="8">
        <div style="visibility: hidden;">11</div>
      </el-col>
      <el-col :md="8">
        <el-form-item label="皮克重量" prop="prod_skin_weight">
          <el-input
          :disabled="!specialDataFlag"
            v-model="prodForm.prod_skin_weight"
            @input="
              prodForm.prod_skin_weight = helper.keepTNum1(
                prodForm.prod_skin_weight
              )
            "
            @blur="
              prodForm.prod_skin_weight = helper.retain1(
                prodForm.prod_skin_weight,
                2,
                1000000
              )
            "
            maxlength="9"
            show-word-limit
            placeholder="请填写皮克重量"
          >
            <template slot="append">克</template>
          </el-input>
        </el-form-item>
      </el-col>
    <!-- </el-row>
    <el-row> -->
      <el-col :md="8">
        <el-form-item label="填充物重量" prop="prod_fill_weight">
          <el-input
          :disabled="!specialDataFlag"
            v-model="prodForm.prod_fill_weight"
            @input="
              prodForm.prod_fill_weight = helper.keepTNum1(
                prodForm.prod_fill_weight
              )
            "
            @blur="
              prodForm.prod_fill_weight = helper.retain1(
                prodForm.prod_fill_weight,
                2,
                1000000
              )
            "
            maxlength="9"
            show-word-limit
            placeholder="请填写填充物重量"
          >
            <template slot="append">克</template>
          </el-input>
        </el-form-item>
      </el-col>
      <!-- </el-row> -->
    <!-- </el-col> -->
      <!-- <el-row> -->
    </el-row>
      <!-- <el-row class="vd_buttom">
        <el-col :md="8">
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              v-model="prodForm.remark"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
        <el-tab-pane label="BOM清单" name="first" :key="'first'">
          <div class="vd_but">
            <!-- <el-button type="primary" plain size="small" @click="addRow()"
              >导入</el-button
            >
            <el-button type="danger" plain size="small" @click="delBom()"
              >删除</el-button
            > -->
          </div>
          <ProdEditBomChild
            :isShow="showBom"
            :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
            :prodForm="prodForm"
            @handleSelectionChange="handleSelectionChange"
            v-loading="bomFlag"
          ></ProdEditBomChild>
        </el-tab-pane>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="70%">
          <ProdEditBom
            @falseBom="falseBom"
            @childBom="childBom"
            @bomChildChanel="bomChildChanel"
            ref="ProdEditBom"
          ></ProdEditBom>
        </el-dialog>
        <el-tab-pane label="加工面料" name="second" :key="'second'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addPlmtrl()"
              >添加</el-button
            >
            <!-- <el-button type="success" plain size="small" @click="importPlmtrl()">导入</el-button> -->
            <el-button type="danger" plain size="small" @click="delPlmtrl()"
              >删除</el-button
            >
          </div>
          <ProdEditPlmtrlChild
            @compListChange="compListChange"
            v-if="showPlmtrl"
            :isShow="isShow"
            :plmtrlShow="plmtrlShow"
            @plmtrlShowChange="plmtrlShowChange"
            @plmtrlChangeCut="plmtrlChangeCut"
            @plmtrlChangeIds="plmtrlChangeIds"
            @deletePlmtrl="deletePlmtrl"
            :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
            :prodForm="prodForm"
            @handleSelectionChange2="handleSelectionChange2"
            v-loading="plmtrlFlag"
          ></ProdEditPlmtrlChild>
        </el-tab-pane>
        <el-dialog :title="title1" :visible.sync="dialogVisible1" width="70%">
          <ProdEditPlmtrl
            @childPlmtrl="childPlmtrl"
            @plmtrlChildChanel="plmtrlChildChanel"
            ref="ProdEditBom"
          ></ProdEditPlmtrl>
        </el-dialog>
        <el-tab-pane label="裁片信息" name="third" :key="'third'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addCut()"
              >添加</el-button
            >
            <el-button type="danger" plain size="small" @click="delCut()"
              >删除</el-button
            >
          </div>
          <ProdEditCutChild
            @cutChangeCuts="cutChangeCuts"
            @cutChangeIds="cutChangeIds"
            :prodForm="prodForm"
            @handleSelectionChange1="handleSelectionChange1"
            v-loading="cutFlag"
          ></ProdEditCutChild>
        </el-tab-pane>
        <el-tab-pane label="在制品信息" name="fourth" :key="'fourth'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addSemiprod()"
              >添加</el-button
            >
            <el-button type="danger" plain size="small" @click="delSemiprod()"
              >删除</el-button
            >
          </div>
          <ProdEditSemiprodChild
            v-if="showSemiprod"
            @semiprodChangeCut="semiprodChangeCut"
            @semiprodChangeIds="semiprodChangeIds"
            :prodForm="prodForm"
            @handleSelectChangeSemiprod="handleSelectChangeSemiprod"
            v-loading="semiprodFlag"
          ></ProdEditSemiprodChild>
        </el-tab-pane>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser
            :isCookie="false"
            :stffForm="stffForm"
            ref="userData"
            v-if="prodForm.stff_name"
          ></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small"  v-if="acctPreseFlag" @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="cancelType()">取消</el-button>
          <el-button class="vg_mb_16" type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="prodForm.prod_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-select size="small" :disabled="!acctPreseFlag" @change="acctSelect(scope)" v-model="prodForm.prod_acct_list[scope.$index].acct_id" placeholder="请选择账套" :clearable="prodForm.role_status === 1">
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link :disabled="!acctPreseFlag" type="danger" class="vg_cursor" @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { prodAPI } from "@api/modules/prod";
import { stffAPI } from "@api/modules/staff";
import { optnAPI } from "@api/modules/optn";
import { imgeAPI } from "@api/modules/imge";
import {acctAPI} from "@api/modules/acct";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import helper from "@assets/js/helper";
import ProdEditBom from "@/views/DevelopManagement/ProdManage/TabChild/Componet/ProdEditBom";
import ProdEditPlmtrl from "@/views/DevelopManagement/ProdManage/TabChild/Componet/ProdEditPlmtrl";
import ProdEditBomChild from "@/views/DevelopManagement/ProdManage/TabChild/Componet/TabPanel/ProdEditBomChild";
import ProdEditPlmtrlChild from "@/views/DevelopManagement/ProdManage/TabChild/Componet/TabPanel/ProdEditPlmtrlChild";
import ProdEditCutChild from "@/views/DevelopManagement/ProdManage/TabChild/Componet/TabPanel/ProdEditCutChild";
import ProdEditSemiprodChild from "@/views/DevelopManagement/ProdManage/TabChild/Componet/TabPanel/ProdEditSemiprodChild";
import imgPopover from "@/views/component/imgPopover";

export default {
  name: "prodEditMain",
  components: {
    editHeader,
    inputUser,
    ProdEditBom,
    ProdEditBomChild,
    ProdEditPlmtrlChild,
    ProdEditCutChild,
    ProdEditPlmtrl,
    ProdEditSemiprodChild,
    imgPopover,
  },
  data() {
    return {
      prodForm: {},
      rules: {
        prod_no: [{ required: true, trigger: "blur", message: " " }],
        prod_name: [{ required: true, trigger: "blur", message: " " }],
        prod_type: [{ required: true, trigger: "change", message: " " }],
      },
      num: 0,
      prod_id: "",
      dialogVisible: false,
      dialogVisible1: false,
      activeName: "first",
      activeName2: "",
      isShow: true,
      btn: {},
      mtrlTypeGroupOpt: [],
      selectionsList: [],
      plmtrlSelect: [],
      cutSelect: [],
      semiprodSelect: [],
      bomCopy: [],
      plmtrlCopy: [],
      cutCopy: [],
      semiprodCopy: [],
      title: "物料信息",
      title1: "加工面料",
      prodType: [
        { id: 1, label: "新款" },
        { id: 2, label: "老款" },
        { id: 3, label: "修改款" },
      ],
      stffType: [],
      bomProdMtrlId: -1,
      cutProdMtrlId: -1001,
      plmtrlProdMtrlId: -501,
      semiprodProdMtrlId: -1501,
      bomFlag: false,
      plmtrlFlag: false,
      cutFlag: false,
      semiprodFlag: false,
      plmtrlOption: [],
      cutOption: [],
      semiprodOption: [],
      stffForm: {
        stff_name: "",
        cptt_name: "",
        dept_name: "",
        dept_team_name: "",
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number,
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400,
      },
      showPlmtrl: true,
      showSemiprod: true,
      showBom: true,
      plmtrlShow:false,
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      show: false,
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null,
      },
      loadingFlag: true,
      drawer: false,
      direction: 'rtl',
      acctCopy:[],
      prodAcctCope:[],
      specialDataFlag:false,
      acctPreseFlag:false
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.bomProdMtrlId = -1;
      this.cutProdMtrlId = -1001;
      this.plmtrlProdMtrlId = -501;
      this.semiprodProdMtrlId = -1501;
      this.plmtrlOption = [];
      this.cutOption = [];
      this.semiprodOption = [];
      this.loadingFlag = true;
      this.getprodInfo();
      // this.getStffType();
      this.getMtrlType();
      this.getAccts()
      if(this.$cookies.get('userInfo').acct_id === 1){
        this.specialDataFlag = true
      }
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.showBom = false;
      this.$emit("isShow", this.isShow);
    },
    //撤销编辑
    closeEdit() {
      if (this.num > 0) {
        return this.$message.warning("请先保存RFId卡信息");
      }
      this.$confirm("是否撤销编辑?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.isShow = true;
          this.showBom = true;
          this.$emit("isShow", this.isShow);
          this.show = true;
          this.bomProdMtrlId = -1;
          this.cutProdMtrlId = -1001;
          this.plmtrlProdMtrlId = -501;
          this.semiprodProdMtrlId = -1501;
          this.plmtrlOption = [];
          this.cutOption = [];
          this.semiprodOption = [];
          this.$message({
            type: "info",
            message: "已撤销编辑!",
          });
          this.initData();
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      if (this.num > 0) {
        return this.$message.warning("请先保存RFId卡信息");
      }
      this.$confirm("确认提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log("error submit!");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let prodForm = {};
      prodForm = Object.assign(prodForm, this.prodForm);
      prodForm.prod_deil_time = parseInt(prodForm.prod_deil_time / 1000);
      this.bomCopy = this.bomCopy.filter((item) =>
        prodForm.prod_mtrl_list.every(
          (item1) => item.prod_mtrl_id !== item1.prod_mtrl_id
        )
      );
      this.plmtrlCopy = this.plmtrlCopy.filter((item) =>
        prodForm.prod_plmtrl_list.every(
          (item1) => item.prod_mtrl_id !== item1.prod_mtrl_id
        )
      );
      this.cutCopy = this.cutCopy.filter((item) =>
        prodForm.prod_cut_list.every(
          (item1) => item.prod_mtrl_id !== item1.prod_mtrl_id
        )
      );
      this.semiprodCopy = this.semiprodCopy.filter((item) =>
        prodForm.prod_semiprod_list.every(
          (item1) => item.prod_mtrl_id !== item1.prod_mtrl_id
        )
      );
      for (let i = 0; i < this.bomCopy.length; i++) {
        this.bomCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.plmtrlCopy.length; i++) {
        this.plmtrlCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.cutCopy.length; i++) {
        this.cutCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.semiprodCopy.length; i++) {
        this.semiprodCopy[i].destroy_flag = 1;
      }
      prodForm.prod_mtrl_list = prodForm.prod_mtrl_list.concat(this.bomCopy);
      prodForm.prod_plmtrl_list = prodForm.prod_plmtrl_list.concat(
        this.plmtrlCopy
      );
      prodForm.prod_cut_list = prodForm.prod_cut_list.concat(this.cutCopy);
      prodForm.prod_semiprod_list = prodForm.prod_semiprod_list.concat(
        this.semiprodCopy
      );
      for (let i = 0; i < prodForm.prod_cut_list.length; i++) {
        prodForm.prod_cut_list[i].mtrl_no =
          prodForm.prod_no + "@" + prodForm.prod_cut_list[i].mtrl_no;
      }
      for (let i = 0; i < prodForm.prod_semiprod_list.length; i++) {
        prodForm.prod_semiprod_list[i].mtrl_no =
          prodForm.prod_no + "#" + prodForm.prod_semiprod_list[i].mtrl_no;
      }
      let flag = prodForm.prod_mtrl_list.some(item =>{
        return item.prod_mtrl_len_wid ==''
      })
      if(flag){
        return this.$message.warning('剪料长宽不可为空')
      }
      post(prodAPI.editProd, prodForm)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.isShow = true;
            this.showBom = true;
            this.bomProdMtrlId = -1;
            this.cutProdMtrlId = -1001;
            this.plmtrlProdMtrlId = -501;
            this.semiprodProdMtrlId = -1501;
            this.plmtrlOption = [];
            this.cutOption = [];
            this.semiprodOption = [];
            this.$emit("isShow", this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: "error",
              message: "单据编号重复",
            });
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取账套accts
    getAccts(){
      get(acctAPI.getAllAcctsV1)
      .then(res =>{
        if(res.data.code === 0){
          this.acctCopy = res.data.data;
          for(let i=0;i<this.acctCopy.length;i++){
            if(this.$cookies.get('userInfo').acct_id === this.acctCopy[i].acct_id){
              this.acctCopy[i].disabled  = true
            }
          }
        }else if(res.data.code === 5){
          console.log('  ');
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 刷新按钮
    buttonRefresh() {
      this.bomProdMtrlId = -1;
      this.cutProdMtrlId = -1001;
      this.plmtrlProdMtrlId = -501;
      this.semiprodProdMtrlId = -1501;
      this.plmtrlOption = [];
      this.cutOption = [];
      this.semiprodOption = [];
      this.initData();
    },
    // 添加图片
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.prodForm.imge_id = res.data.imge_id;
        this.prodForm.imge_url = this.helper.picUrl(urlEng, "s",updateTime,0);
        this.prodForm.imge_urlO = this.helper.picUrl(urlEng, "l",updateTime,0);
      } else {
        let mg = res.msg;
        let tp = "error";
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 裁片选择框选中
    handleSelectChangeSemiprod(val) {
      this.semiprodSelect = [];
      this.semiprodSelect = val;
    },
    // 关闭特殊数据范围
    handleCloseDrawer(){
      let flag = false;
      for(let i=0;i<this.prodForm.prod_acct_list.length;i++){
        if(this.prodForm.prod_acct_list[i].acct_id === null){
          flag = true;
        }
      }
      if(flag){
        this.$message.warning('请把分享的账套补充完整')
      }else{
        if(this.acctPreseFlag){
          this.$message.warning('请先保存或取消分享账套的编辑')
        }else{
          this.drawer = false
        }
      }
    },
    // 保存分享账套
    preseType(){
      let temp = false
      for(let i=0;i<this.prodForm.prod_acct_list.length;i++){
        if(this.prodForm.prod_acct_list[i].acct_id === null){
          temp = true
        }
      }
      if(temp){
        this.$message.warning("请把账套名称补充完整")
      }else{
        this.$confirm("确认保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res)=>{
          let acctList = JSON.parse(JSON.stringify(this.prodForm.prod_acct_list))
          this.prodAcctCope = this.prodAcctCope.filter(item=>acctList.every(item1=>item.prod_acct_id !== item1.prod_acct_id))
          for(let i=0;i<this.prodAcctCope.length;i++){
            this.prodAcctCope[i].destroy_flag = 1
          }
          acctList = acctList.concat(this.prodAcctCope)
          if(acctList.length>0){
            post(prodAPI.prodShareAccts,{prod_id:this.prodForm.prod_id,prod_acct_list:acctList})
            .then(res=>{
              if(res.data.code === 0){
                this.$message.success("保存成功")
                // this.drawer = false
                this.acctPreseFlag = false
                this.initData()
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            })
          }else{
            this.$message.success("保存成功")
            this.acctPreseFlag = false
            this.initData()
          }
        })
        .catch(()=>{})  
      }
    },
    // 取消分享账套
    cancelType(){
      this.$confirm("确认取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((res)=>{
        this.acctPreseFlag = false
        this.initData()
      })
      .catch(()=>{})
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id:null
      };
      this.prodForm.prod_acct_list.push(item)
    },
    // 特殊数据范围添加删除
    delType(scope){
      if(scope.row.disabled){
        this.$message.error("该账套不能删除")
      }else{
        this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.prodForm.prod_acct_list.splice(scope.$index,1)
        })
        .catch(() => {});
      }
    },
    // 选择账套重复判断
    acctSelect(val){
      if(val.row.acct_id !== null){
        for(let i=0;i<this.prodForm.prod_acct_list.length;i++){
          if(i !== val.$index){
            if(this.prodForm.prod_acct_list[i].acct_id === val.row.acct_id){
              val.row.acct_id = null;
              this.$message({
                type:'warning',
                message:'账套选择重复'
              })
              return ;
            }
          }
        }
      }
    },
    // 在制品新增
    addSemiprod() {
      if (
        (this.prodForm.prod_semiprod_list.length > 0 &&
          this.prodForm.prod_semiprod_list[
            this.prodForm.prod_semiprod_list.length - 1
          ].mtrl_no) ||
        this.prodForm.prod_semiprod_list.length === 0
      ) {
        this.semiprodFlag = true;
        setTimeout(() => {
          let item = {
            mtrl_no: null,
            mtrl_name: null,
            prod_parent_ids: null,
            prod_mtrl_id: this.semiprodProdMtrlId,
            prod_parent_list: [],
            semiprodOption: JSON.parse(JSON.stringify(this.semiprodOption)),
          };
          this.prodForm.prod_semiprod_list.push(item);
          this.semiprodProdMtrlId--;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message.warning("请把物料编号补充完整再进行添加");
      }
    },
    // 在制品删除
    delSemiprod() {
      if (this.semiprodSelect.length > 0) {
        this.semiprodFlag = true;
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        let temp = [];
        for (let i = 0; i < this.semiprodSelect.length; i++) {
          let ind = null;
          ind = this.prodForm.prod_semiprod_list.indexOf(
            this.semiprodSelect[i]
          );
          temp.push(ind);
          for (let j = 0; j < this.prodForm.prod_semiprod_list.length; j++) {
            for (
              let a = 0;
              a < this.prodForm.prod_semiprod_list[j].prod_parent_list.length;
              a++
            ) {
              if (
                Number(this.semiprodSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_semiprod_list[j].prod_parent_list[a]
              ) {
                this.prodForm.prod_semiprod_list[j].prod_parent_list.splice(
                  a,
                  1
                );
                break;
              }
            }
            for (
              let a = 0;
              a < this.prodForm.prod_semiprod_list[j].semiprodOption.length;
              a++
            ) {
              if (
                Number(this.semiprodSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_semiprod_list[j].semiprodOption[a]
                  .prod_mtrl_id
              ) {
                this.prodForm.prod_semiprod_list[j].semiprodOption.splice(a, 1);
                break;
              }
            }
          }
          for (let j in this.semiprodOption) {
            if (
              Number(this.semiprodSelect[i].prod_mtrl_id) ===
              this.semiprodOption[j].prod_mtrl_id
            ) {
              this.semiprodOption.splice(j, 1);
              break;
            }
          }
        }
        for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
          if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 0
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids = "";
          } else if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 1
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[
                i
              ].prod_parent_list[0].toString();
          } else {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[i].prod_parent_list.split(",");
          }
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.prodForm.prod_semiprod_list.splice(j, 1);
        }
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: "至少选择一条信息",
          type: "warning",
        });
      }
    },
    // 在制品回显使用物料
    semiprodChangeIds(val) {
      this.showSemiprod = false;
      this.prodForm.prod_semiprod_list[val.$index].prod_parent_ids =
        val.row.prod_parent_list.join(",");
      this.$nextTick(() => {
        this.showSemiprod = true;
      });
    },
    //在制品填写编号时修改在制品的一些选项值
    semiprodChangeCut(scope) {
      if (scope.row.mtrl_no.indexOf(" ") === -1) {
        let semiprodTemp = -1;
        let temp = {};
        temp.prod_mtrl_id = scope.row.prod_mtrl_id;
        temp.mtrl_no = this.prodForm.prod_no + "#" + scope.row.mtrl_no;
        for (let i = 0; i < this.semiprodOption.length; i++) {
          if (this.semiprodOption[i].prod_mtrl_id === scope.row.prod_mtrl_id) {
            semiprodTemp = i;
          }
        }
        if (semiprodTemp !== -1) {
          this.semiprodOption[semiprodTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.semiprodOption.push(temp);
        }
        for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
          for (
            let j = 0;
            j < this.prodForm.prod_semiprod_list[i].semiprodOption.length;
            j++
          ) {
            if (
              this.prodForm.prod_semiprod_list[i].semiprodOption[j]
                .prod_mtrl_id === scope.row.prod_mtrl_id
            ) {
              this.prodForm.prod_semiprod_list[i].semiprodOption[j].mtrl_no =
                this.prodForm.prod_no + "#" + scope.row.mtrl_no;
            }
          }
        }
      }
    },
    // 裁片选择框选中
    handleSelectionChange1(val) {
      this.cutSelect = [];
      this.cutSelect = val;
    },
    //在制品填写编号时修改在制品的一些选项值
    cutChangeCuts(scope) {
      if (scope.row.mtrl_no.indexOf(" ") === -1) {
        let semiprodTemp = -1;
        let temp = {};
        temp.prod_mtrl_id = scope.row.prod_mtrl_id;
        temp.mtrl_no = this.prodForm.prod_no + "@" + scope.row.mtrl_no;
        for (let i = 0; i < this.semiprodOption.length; i++) {
          if (this.semiprodOption[i].prod_mtrl_id === scope.row.prod_mtrl_id) {
            semiprodTemp = i;
          }
        }
        if (semiprodTemp !== -1) {
          this.semiprodOption[semiprodTemp].mtrl_no = temp.mtrl_no;
        } else {
          this.semiprodOption.push(temp);
        }
        for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
          this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          // for (
          //   let j = 0;
          //   j < this.prodForm.prod_semiprod_list[i].semiprodOption.length;
          //   j++
          // ) {
          //   if (
          //     this.prodForm.prod_semiprod_list[i].semiprodOption[j]
          //       .prod_mtrl_id === scope.row.prod_mtrl_id
          //   ) {
          //     this.prodForm.prod_semiprod_list[i].semiprodOption[j].mtrl_no =
          //       this.prodForm.prod_no + "@" + scope.row.mtrl_no;
          //   }
          // }
        }
      }
    },
    // 裁片新增
    addCut() {
      this.cutFlag = true
      let item = {
        mtrl_no: null,
        mtrl_name: null,
        prod_parent_ids: null,
        prod_mtrl_id: this.cutProdMtrlId,
        prod_parent_value: null,
        cutOption: JSON.parse(JSON.stringify(this.cutOption)),
      };
      this.prodForm.prod_cut_list.push(item);
      this.cutProdMtrlId--;
      setTimeout(() => {
        this.cutFlag = false;
      }, 500);
    },
    // 裁片回显使用物料
    cutChangeIds(val) {
      this.prodForm.prod_cut_list[val.$index].prod_parent_ids =
        val.row.prod_parent_value.toString();
    },
    // 裁片删除
    delCut() {
      if (this.cutSelect.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        this.showSemiprod = false;
        let temp = [];
        for (let i = 0; i < this.cutSelect.length; i++) {
          let ind = null;
          ind = this.prodForm.prod_cut_list.indexOf(this.cutSelect[i]);
          temp.push(ind);
          for (let j = 0; j < this.prodForm.prod_semiprod_list.length; j++) {
            for (
              let a = 0;
              a < this.prodForm.prod_semiprod_list[j].prod_parent_list.length;
              a++
            ) {
              if (
                Number(this.cutSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_semiprod_list[j].prod_parent_list[a]
              ) {
                this.prodForm.prod_semiprod_list[j].prod_parent_list.splice(
                  a,
                  1
                );
                break;
              }
            }
            for (
              let a = 0;
              a < this.prodForm.prod_semiprod_list[j].semiprodOption.length;
              a++
            ) {
              if (
                Number(this.cutSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_semiprod_list[j].semiprodOption[a]
                  .prod_mtrl_id
              ) {
                this.prodForm.prod_semiprod_list[j].semiprodOption.splice(a, 1);
                break;
              }
            }
          }
          for (let j in this.semiprodOption) {
            if (
              Number(this.cutSelect[i].prod_mtrl_id) ===
              this.semiprodOption[j].prod_mtrl_id
            ) {
              this.semiprodOption.splice(j, 1);
              break;
            }
          }
        }
        for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
          // this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 0
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids = "";
          } else if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 1
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[
                i
              ].prod_parent_list[0].toString();
          } else {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[i].prod_parent_list.split(",");
          }
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.prodForm.prod_cut_list.splice(j, 1);
        }
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
          this.showSemiprod = true;
        }, 500);
      } else {
        this.$message({
          message: "至少选择一条信息",
          type: "warning",
        });
      }
    },
    // 加工面料导入打开物料选择
    importPlmtrl() {
      this.dialogVisible1 = true;
    },
    //新增数据填写编号时修改裁片的值
    plmtrlChangeCut(scope) {
      if (scope.row.mtrl_no.indexOf(" ") === -1) {
        this.showPlmtrl = false;
        let plmtrlTemp = -1;
        let cutTemp = -1;
        let temp = {};
        temp.prod_mtrl_id = scope.row.prod_mtrl_id;
        temp.mtrl_no = scope.row.mtrl_no;
        for (let i = 0; i < this.cutOption.length; i++) {
          if (this.cutOption[i].prod_mtrl_id === scope.row.prod_mtrl_id) {
            cutTemp = i;
          }
        }
        for (let i = 0; i < this.plmtrlOption.length; i++) {
          if (this.plmtrlOption[i].prod_mtrl_id === scope.row.prod_mtrl_id) {
            plmtrlTemp = i;
          }
        }
        if (cutTemp !== -1) {
          this.cutOption[cutTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.cutOption.push(temp);
        }
        if (plmtrlTemp !== -1) {
          this.plmtrlOption[plmtrlTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.plmtrlOption.push(temp);
        }
        for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
          this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
            JSON.stringify(this.cutOption)
          );
        }
        for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
          for (
            let j = 0;
            j < this.prodForm.prod_plmtrl_list[i].plmtrlOption.length;
            j++
          ) {
            if (
              this.prodForm.prod_plmtrl_list[i].plmtrlOption[j].prod_mtrl_id ===
              scope.row.prod_mtrl_id
            ) {
              this.prodForm.prod_plmtrl_list[i].plmtrlOption[j].mtrl_no =
                scope.row.mtrl_no;
            }
            }
          for (
              let j = 0;
              j < this.prodForm.prod_plmtrl_list[i].prod_parent_listO.length;
              j++
            ) {
              if (
                this.prodForm.prod_plmtrl_list[i].prod_parent_listO[j].prod_mtrl_id ===
                scope.row.prod_mtrl_id
              ) {
                this.prodForm.prod_plmtrl_list[i].prod_parent_listO[j].mtrl_no =
                  scope.row.mtrl_no;
            }
          }
        }
        this.$nextTick(() => {
          this.showPlmtrl = true;
        });
      }
    },
    compListChange(val, num) {
      if(this.prodForm.prod_plmtrl_list[val.$index].mtrl_no){
        this.showPlmtrl = false;
        if (num === 0) {
          if (this.prodForm.prod_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.prodForm.prod_plmtrl_list[val.$index].mtrl_no =
              this.prodForm.prod_plmtrl_list[val.$index].mtrl_no + "-F";
          }
        } else if (num === 1) {
          if (this.prodForm.prod_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.prodForm.prod_plmtrl_list[val.$index].mtrl_no =
              this.prodForm.prod_plmtrl_list[val.$index].mtrl_no + "-Y";
          }
        } else if (num === 2) {
          if (this.prodForm.prod_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.prodForm.prod_plmtrl_list[val.$index].mtrl_no =
              this.prodForm.prod_plmtrl_list[val.$index].mtrl_no + "-D";
          }
        }
        let plmtrlTemp = -1;
        let cutTemp = -1;
        let temp = {};
        temp.prod_mtrl_id = val.row.prod_mtrl_id;
        temp.mtrl_no = this.prodForm.prod_plmtrl_list[val.$index].mtrl_no;
        for (let i = 0; i < this.cutOption.length; i++) {
          if (this.cutOption[i].prod_mtrl_id === val.row.prod_mtrl_id) {
            cutTemp = i;
          }
        }
        for (let i = 0; i < this.plmtrlOption.length; i++) {
          if (this.plmtrlOption[i].prod_mtrl_id === val.row.prod_mtrl_id) {
            plmtrlTemp = i;
          }
        }
        if (cutTemp !== -1) {
          this.cutOption[cutTemp].mtrl_no =
            this.prodForm.prod_plmtrl_list[val.$index].mtrl_no;
        } else {
          this.cutOption.push(temp);
        }
        if (plmtrlTemp !== -1) {
          this.plmtrlOption[plmtrlTemp].mtrl_no =
            this.prodForm.prod_plmtrl_list[val.$index].mtrl_no;
        } else {
          this.plmtrlOption.push(temp);
        }
        for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
          this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
            JSON.stringify(this.cutOption)
          );
        }
        for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
          for (
            let j = 0;
            j < this.prodForm.prod_plmtrl_list[i].plmtrlOption.length;
            j++
          ) {
            if (
              this.prodForm.prod_plmtrl_list[i].plmtrlOption[j].prod_mtrl_id ===
              val.row.prod_mtrl_id
            ) {
              this.prodForm.prod_plmtrl_list[i].plmtrlOption[j].mtrl_no =
                this.prodForm.prod_plmtrl_list[val.$index].mtrl_no;
            }
          }
        }
        this.$nextTick(() => {
          this.showPlmtrl = true;
        });
      }else{
        setTimeout(() => {
          if(this.plmtrlShow){
            this.plmtrlShow = false
            return 
          }
          this.$message.warning('请填写物料编号')
        }, 100);
      }
    },
    // plmtrlShowChange变化
    plmtrlShowChange(){
      this.plmtrlShow = true
    },
    // 加工面料小窗口确定
    childPlmtrl(val) {
      let plmtrlImportVal = val;
      plmtrlImportVal = plmtrlImportVal.filter((item) =>
        this.prodForm.prod_plmtrl_list.every(
          (item1) => item.mtrl_id !== item1.mtrl_id
        )
      );
      for (let i of plmtrlImportVal) {
        i.prod_mtrl_id = this.plmtrlProdMtrlId;
        i.plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption));
        this.plmtrlProdMtrlId--;
      }
      for (let i = 0; i < plmtrlImportVal.length; i++) {
        let item = {};
        item.prod_mtrl_id = plmtrlImportVal[i].prod_mtrl_id;
        item.mtrl_no = plmtrlImportVal[i].mtrl_no;
        this.cutOption.push(item);
        this.plmtrlOption.push(item);
      }
      for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
        this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
          JSON.stringify(this.cutOption)
        );
      }
      this.prodForm.prod_plmtrl_list =
        this.prodForm.prod_plmtrl_list.concat(plmtrlImportVal);
      this.$refs.ProdEditBom.clear();
      this.dialogVisible1 = false;
    },
    // 加工面料小窗口取消
    plmtrlChildChanel() {
      this.dialogVisible1 = false;
    },
    // 加工面料选择框选中
    handleSelectionChange2(val) {
      this.plmtrlSelect = [];
      this.plmtrlSelect = val;
    },
    // 加工面料删除回显物料
    deletePlmtrl(scope,val){
      this.showPlmtrl = false;
      let temp = JSON.parse(JSON.stringify(val));
      let ind = null
      for(let i=0;i<this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.length;i++){
        this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO[i].key = i;
        if(this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO[i].prod_mtrl_id === temp.prod_mtrl_id){
          ind = i
        }
      }
      if(this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.indexOf(temp.prod_mtrl_id) !== -1){
        this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.splice(this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.indexOf(temp.prod_mtrl_id),1)
        this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_ids = this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.join(",");
      }
      this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.splice(ind,1)
      if(this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.length>0){
        this.prodForm.prod_plmtrl_list[scope.$index].mtrl_no = this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO[0].mtrl_no
      }else{
        this.prodForm.prod_plmtrl_list[scope.$index].mtrl_no = ''
      }
      this.$nextTick(() => {
        this.showPlmtrl = true;
      });
    },
    // 加工面料回显使用物料
    plmtrlChangeIds(scope,val) {
      this.showPlmtrl = false;
      for(let i=0;i<this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.length;i++){
        this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO[i].key = i;
      }
      let temp = JSON.parse(JSON.stringify(val));
      temp.key = this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.length;
      this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO.push(JSON.parse(JSON.stringify(temp)))
      this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.push(JSON.parse(JSON.stringify(Number(temp.prod_mtrl_id))))
      this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_ids = this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_list.join(",");
      this.prodForm.prod_plmtrl_list[scope.$index].mtrl_no = this.prodForm.prod_plmtrl_list[scope.$index].prod_parent_listO[0].mtrl_no;
      this.$nextTick(() => {
        this.showPlmtrl = true;
      });
    },
    // 加工面料新增
    addPlmtrl() {
      if (
        (this.prodForm.prod_plmtrl_list.length > 0 &&
          this.prodForm.prod_plmtrl_list[
            this.prodForm.prod_plmtrl_list.length - 1
          ].mtrl_no) ||
        this.prodForm.prod_plmtrl_list.length === 0
      ) {
        this.plmtrlFlag = true;
        setTimeout(() => {
          let item = {
            mtrl_no: null,
            mtrl_name: null,
            mtrl_unit: null,
            mtrl_prod_price: "0.0000",
            prod_parent_ids: null,
            prod_mtrl_id: this.plmtrlProdMtrlId,
            prod_parent_list: [],
            prod_parent_listO:[],
            plmtrlOption: JSON.parse(JSON.stringify(this.plmtrlOption)),
          };
          this.prodForm.prod_plmtrl_list.push(item);
          this.plmtrlProdMtrlId--;
          this.plmtrlFlag = false;
        }, 500);
      } else {
        this.$message.warning("请把物料编号补充完整");
      }
    },
    // 加工面料删除
    delPlmtrl() {
      if (this.plmtrlSelect.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        let temp = [];
        for (let i = 0; i < this.plmtrlSelect.length; i++) {
          let ind = null;
          ind = this.prodForm.prod_plmtrl_list.indexOf(this.plmtrlSelect[i]);
          temp.push(ind);
          for (let j = 0; j < this.prodForm.prod_plmtrl_list.length; j++) {
            for (
              let a = 0;
              a < this.prodForm.prod_plmtrl_list[j].prod_parent_list.length;
              a++
            ) {
              if (
                Number(this.plmtrlSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_plmtrl_list[j].prod_parent_list[a]
              ) {
                this.prodForm.prod_plmtrl_list[j].prod_parent_list.splice(a, 1);
                break;
              }
            }
            for (
              let a = 0;
              a < this.prodForm.prod_plmtrl_list[j].prod_parent_listO.length;
              a++
            ) {
              if (
                Number(this.plmtrlSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_plmtrl_list[j].prod_parent_listO[a].prod_mtrl_id
              ) {
                this.prodForm.prod_plmtrl_list[j].prod_parent_listO.splice(a, 1);
                break;
              }
            }
            for (
              let a = 0;
              a < this.prodForm.prod_plmtrl_list[j].plmtrlOption.length;
              a++
            ) {
              if (
                Number(this.plmtrlSelect[i].prod_mtrl_id) ===
                this.prodForm.prod_plmtrl_list[j].plmtrlOption[a].prod_mtrl_id
              ) {
                this.prodForm.prod_plmtrl_list[j].plmtrlOption.splice(a, 1);
                break;
              }
            }
            for (
              let a = 0;
              a < this.prodForm.prod_plmtrl_list[j].prod_parent_listO.length;
              a++
            ) {
              this.prodForm.prod_plmtrl_list[j].prod_parent_listO.key = a;
            }
          }
          for (let j = 0; j < this.prodForm.prod_cut_list.length; j++) {
            if (
              Number(this.plmtrlSelect[i].prod_mtrl_id) ===
              this.prodForm.prod_cut_list[j].prod_parent_value
            ) {
              this.prodForm.prod_cut_list[j].prod_parent_value = null;
              this.prodForm.prod_cut_list[j].prod_parent_ids = "";
            }
          }
          for (let j in this.plmtrlOption) {
            if (
              Number(this.plmtrlSelect[i].prod_mtrl_id) ===
              this.plmtrlOption[j].prod_mtrl_id
            ) {
              this.plmtrlOption.splice(j, 1);
              break;
            }
          }
          for (let j in this.cutOption) {
            if (
              Number(this.plmtrlSelect[i].prod_mtrl_id) ===
              this.cutOption[j].prod_mtrl_id
            ) {
              this.cutOption.splice(j, 1);
              break;
            }
          }
        }
        // for(let i=0;i<this.prodForm.prod_plmtrl_list.length;i++){
        //   this.prodForm.prod_plmtrl_list[i].plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption))
        // }
        for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
          // this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.prodForm.prod_plmtrl_list[i].prod_parent_list.length === 0) {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids = "";
          } else if (
            this.prodForm.prod_plmtrl_list[i].prod_parent_list.length === 1
          ) {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids =
              this.prodForm.prod_plmtrl_list[i].prod_parent_list[0].toString();
          } else {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids =
              this.prodForm.prod_plmtrl_list[i].prod_parent_list.join(",");
          }
        }
        for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
          this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
            JSON.stringify(this.cutOption)
          );
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.prodForm.prod_plmtrl_list.splice(j, 1);
        }
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: "至少选择一条信息",
          type: "warning",
        });
      }
    },
    // bom清单小窗口取消
    bomChildChanel() {
      this.dialogVisible = false;
    },
    falseBom() {
      this.bomChildChanel();
    },
    // bom清单小窗口确定
    childBom(val) {
      let bomImportVal = val;
      bomImportVal = bomImportVal.filter((item) =>
        this.prodForm.prod_mtrl_list.every(
          (item1) => item.mtrl_id !== item1.mtrl_id
        )
      );
      for (let i of bomImportVal) {
        i.prod_mtrl_id = this.bomProdMtrlId;
        this.bomProdMtrlId--;
      }
      for (let i = 0; i < bomImportVal.length; i++) {
        let item = {};
        item.prod_mtrl_id = bomImportVal[i].prod_mtrl_id;
        item.mtrl_no = bomImportVal[i].mtrl_no;
        if (bomImportVal[i].mtrl_type === 0) {
          this.plmtrlOption.push(item);
          this.cutOption.push(item);
          for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
            this.prodForm.prod_plmtrl_list[i].plmtrlOption.push(item);
          }
        } else {
          this.semiprodOption.push(item);
          for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
            this.prodForm.prod_semiprod_list[i].semiprodOption.push(item);
          }
        }
      }
      for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
        this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
          JSON.stringify(this.cutOption)
        );
      }
      this.prodForm.prod_mtrl_list =
        this.prodForm.prod_mtrl_list.concat(bomImportVal);
      this.$refs.ProdEditBom.clear();
      this.dialogVisible = false;
    },
    //bom清单导入
    addRow() {
      this.dialogVisible = true;
    },
    //bom清单多选删除
    delBom() {
      let f = this.selectionsList.map((item) => {
        return item.prod_mtrl_status == 1;
      });

      if (f.indexOf(true) != -1) {
        return this.$message.warning("已出库的不可以删除");
      }
      if (this.selectionsList.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        let temp = [];
        for (let i = 0; i < this.selectionsList.length; i++) {
          let ind = null;
          ind = this.prodForm.prod_mtrl_list.indexOf(this.selectionsList[i]);
          temp.push(ind);
          if (this.selectionsList[i].mtrl_type === 0) {
            for (let j = 0; j < this.prodForm.prod_plmtrl_list.length; j++) {
              for (
                let a = 0;
                a < this.prodForm.prod_plmtrl_list[j].prod_parent_list.length;
                a++
              ) {
                if (
                  Number(this.selectionsList[i].prod_mtrl_id) ===
                  this.prodForm.prod_plmtrl_list[j].prod_parent_list[a]
                ) {
                  this.prodForm.prod_plmtrl_list[j].prod_parent_list.splice(
                    a,
                    1
                  );
                  break;
                }
              }
              for (
                let a = 0;
                a < this.prodForm.prod_plmtrl_list[j].prod_parent_listO.length;
                a++
              ) {
                if (
                  Number(this.selectionsList[i].prod_mtrl_id) ===
                  this.prodForm.prod_plmtrl_list[j].prod_parent_listO[a].prod_mtrl_id
                ) {
                  this.prodForm.prod_plmtrl_list[j].prod_parent_listO.splice(
                    a,
                    1
                  );
                  break;
                }
              }
              for (
                let a = 0;
                a < this.prodForm.prod_plmtrl_list[j].plmtrlOption.length;
                a++
              ) {
                if (
                  Number(this.selectionsList[i].prod_mtrl_id) ===
                  this.prodForm.prod_plmtrl_list[j].plmtrlOption[a].prod_mtrl_id
                ) {
                  this.prodForm.prod_plmtrl_list[j].plmtrlOption.splice(a, 1);
                  break;
                }
              }
              for (
                let a = 0;
                a < this.prodForm.prod_plmtrl_list[j].prod_parent_listO.length;
                a++
              ) {
                this.prodForm.prod_plmtrl_list[j].prod_parent_listO.key = a;
              }
            }
            for (let j = 0; j < this.prodForm.prod_cut_list.length; j++) {
              if (
                Number(this.selectionsList[i].prod_mtrl_id) ===
                this.prodForm.prod_cut_list[j].prod_parent_value
              ) {
                this.prodForm.prod_cut_list[j].prod_parent_value = null;
                this.prodForm.prod_cut_list[j].prod_parent_ids = "";
              }
            }
            for (let j in this.plmtrlOption) {
              if (
                Number(this.selectionsList[i].prod_mtrl_id) ===
                this.plmtrlOption[j].prod_mtrl_id
              ) {
                this.plmtrlOption.splice(j, 1);
                break;
              }
            }
            for (let j in this.cutOption) {
              if (
                Number(this.selectionsList[i].prod_mtrl_id) ===
                this.cutOption[j].prod_mtrl_id
              ) {
                this.cutOption.splice(j, 1);
                break;
              }
            }
          } else {
            for (let j = 0; j < this.prodForm.prod_semiprod_list.length; j++) {
              for (
                let a = 0;
                a < this.prodForm.prod_semiprod_list[j].prod_parent_list.length;
                a++
              ) {
                if (
                  Number(this.selectionsList[i].prod_mtrl_id) ===
                  this.prodForm.prod_semiprod_list[j].prod_parent_list[a]
                ) {
                  this.prodForm.prod_semiprod_list[j].prod_parent_list.splice(
                    a,
                    1
                  );
                  break;
                }
              }
              for (
                let a = 0;
                a < this.prodForm.prod_semiprod_list[j].semiprodOption.length;
                a++
              ) {
                if (
                  Number(this.selectionsList[i].prod_mtrl_id) ===
                  this.prodForm.prod_semiprod_list[j].semiprodOption[a]
                    .prod_mtrl_id
                ) {
                  this.prodForm.prod_semiprod_list[j].semiprodOption.splice(
                    a,
                    1
                  );
                  break;
                }
              }
            }
            for (let j in this.semiprodOption) {
              if (
                Number(this.selectionsList[i].prod_mtrl_id) ===
                this.semiprodOption[j].prod_mtrl_id
              ) {
                this.semiprodOption.splice(j, 1);
                break;
              }
            }
          }
        }
        for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
          // this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.prodForm.prod_plmtrl_list[i].prod_parent_list.length === 0) {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids = "";
          } else if (
            this.prodForm.prod_plmtrl_list[i].prod_parent_list.length === 1
          ) {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids =
              this.prodForm.prod_plmtrl_list[i].prod_parent_list[0].toString();
          } else {
            this.prodForm.prod_plmtrl_list[i].prod_parent_ids =
              this.prodForm.prod_plmtrl_list[i].prod_parent_list.join(",");
          }
        }
        for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
          // this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 0
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids = "";
          } else if (
            this.prodForm.prod_semiprod_list[i].prod_parent_list.length === 1
          ) {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[
                i
              ].prod_parent_list[0].toString();
          } else {
            this.prodForm.prod_semiprod_list[i].prod_parent_ids =
              this.prodForm.prod_semiprod_list[i].prod_parent_list.join(",");
          }
        }
        // for(let i=0;i<this.prodForm.prod_plmtrl_list.length;i++){
        //   this.prodForm.prod_plmtrl_list[i].plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption))
        // }
        for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
          this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
            JSON.stringify(this.cutOption)
          );
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.prodForm.prod_mtrl_list.splice(j, 1);
        }
        setTimeout(() => {
          console.log('jieo',this.prodForm.prod_plmtrl_list);
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: "至少选择一条信息",
          type: "warning",
        });
      }
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取委托人
    getStffType() {
      get(stffAPI.getAllStffsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.stffType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then((res) => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取表单信息
    getprodInfo() {
      this.prodForm.prod_id = this.$route.query.form_id;
      get(prodAPI.getProdById, { prod_id: this.prodForm.prod_id })
        .then((res) => {
          if (res.data.code === 0) {
            this.bomFlag = true;
            this.plmtrlFlag = true;
            this.cutFlag = true;
            this.semiprodFlag = true;
            this.showPlmtrl = false;
            this.prodForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.prodAcctCope = JSON.parse(JSON.stringify(this.prodForm.prod_acct_list))
            let urlEng = JSON.parse(JSON.stringify(this.prodForm.imge_url));
            if (this.prodForm.imge_id) {
              this.dataBody.imge_id = this.prodForm.imge_id;
            } else {
              this.dataBody.imge_id = 0;
            }
            
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
            this.prodForm.imge_url = this.helper.picUrl(urlEng, "s",updateTime,this.prodForm.imge_source);
            this.prodForm.imge_urlO = this.helper.picUrl(urlEng, "l",updateTime,this.prodForm.imge_source);
            if (this.show) {
              this.prodForm.imge_id = this.imgForm.imge_id;
              this.prodForm.imge_url = this.imgForm.imge_url;
              this.prodForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(
              JSON.stringify(this.prodForm.imge_url)
            );
            this.imgForm.imge_urlO = JSON.parse(
              JSON.stringify(this.prodForm.imge_urlO)
            );
            this.imgForm.imge_id = JSON.parse(
              JSON.stringify(this.prodForm.imge_id)
            );
            if (this.prodForm.prod_price !== null) {
              this.prodForm.prod_price = this.helper.haveFour(
                this.prodForm.prod_price
              );
            }
            if (this.prodForm.prod_verify_price !== null) {
              this.prodForm.prod_verify_price = this.helper.haveFour(
                this.prodForm.prod_verify_price
              );
            }
            if (this.prodForm.prod_freight !== null) {
              this.prodForm.prod_freight = this.helper.haveFour(
                this.prodForm.prod_freight
              );
            }
            if (this.prodForm.prod_bag_l !== null) {
              this.prodForm.prod_bag_l = this.helper.reservedDigits(
                this.prodForm.prod_bag_l
              );
            }
            if (this.prodForm.prod_bag_w !== null) {
              this.prodForm.prod_bag_w = this.helper.reservedDigits(
                this.prodForm.prod_bag_w
              );
            }
            if (this.prodForm.prod_box_h !== null) {
              this.prodForm.prod_box_h = this.helper.reservedDigits(
                this.prodForm.prod_box_h
              );
            }
            if (this.prodForm.prod_box_l !== null) {
              this.prodForm.prod_box_l = this.helper.reservedDigits(
                this.prodForm.prod_box_l
              );
            }
            if (this.prodForm.prod_box_w !== null) {
              this.prodForm.prod_box_w = this.helper.reservedDigits(
                this.prodForm.prod_box_w
              );
            }
            if (this.prodForm.prod_fill_weight !== null) {
              this.prodForm.prod_fill_weight = this.helper.reservedDigits(
                this.prodForm.prod_fill_weight
              );
            }
            if (this.prodForm.prod_skin_weight !== null) {
              this.prodForm.prod_skin_weight = this.helper.reservedDigits(
                this.prodForm.prod_skin_weight
              );
            }
            if (this.prodForm.prod_weight !== null) {
              this.prodForm.prod_weight = this.helper.reservedDigits(
                this.prodForm.prod_weight
              );
            }
            this.bomCopy = JSON.parse(
              JSON.stringify(this.prodForm.prod_mtrl_list)
            );
            this.plmtrlCopy = JSON.parse(
              JSON.stringify(this.prodForm.prod_plmtrl_list)
            );
            this.cutCopy = JSON.parse(
              JSON.stringify(this.prodForm.prod_cut_list)
            );
            this.semiprodCopy = JSON.parse(
              JSON.stringify(this.prodForm.prod_semiprod_list)
            );
            for (let i = 0; i < this.prodForm.prod_mtrl_list.length; i++) {
              let item = {};
              item.prod_mtrl_id = this.prodForm.prod_mtrl_list[i].prod_mtrl_id;
              item.mtrl_no = this.prodForm.prod_mtrl_list[i].mtrl_no;
              if (this.prodForm.prod_mtrl_list[i].mtrl_type === 0) {
                this.plmtrlOption.push(item);
                this.cutOption.push(item);
              } else {
                this.semiprodOption.push(item);
              }
              if (this.prodForm.prod_mtrl_list[i].mtrl_prod_price !== null) {
                this.prodForm.prod_mtrl_list[i].mtrl_prod_price =
                  this.helper.haveFour(
                    this.prodForm.prod_mtrl_list[i].mtrl_prod_price
                  );
              }
              if (this.prodForm.prod_mtrl_list[i].mtrl_width !== null) {
                this.prodForm.prod_mtrl_list[i].mtrl_width =
                  this.helper.haveFour(
                    this.prodForm.prod_mtrl_list[i].mtrl_width
                  );
              }
              if (this.prodForm.prod_mtrl_list[i].mtrl_thick !== null) {
                this.prodForm.prod_mtrl_list[i].mtrl_thick =
                  this.helper.haveFour(
                    this.prodForm.prod_mtrl_list[i].mtrl_thick
                  );
              }
              if (this.prodForm.prod_mtrl_list[i].mtrl_weight !== null) {
                this.prodForm.prod_mtrl_list[i].mtrl_weight =
                  this.helper.reservedDigits(
                    this.prodForm.prod_mtrl_list[i].mtrl_weight
                  );
              }
              if (this.prodForm.prod_mtrl_list[i].prod_mtrl_length !== null) {
                this.prodForm.prod_mtrl_list[i].prod_mtrl_length =
                  this.helper.reservedDigits(
                    this.prodForm.prod_mtrl_list[i].prod_mtrl_length
                  );
              }
              if (this.prodForm.prod_mtrl_list[i].prod_mtrl_width !== null) {
                this.prodForm.prod_mtrl_list[i].prod_mtrl_width =
                  this.helper.reservedDigits(
                    this.prodForm.prod_mtrl_list[i].prod_mtrl_width
                  );
              }
            }
            for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
              this.prodForm.prod_plmtrl_list[i].plmtrlOption = JSON.parse(
                JSON.stringify(this.plmtrlOption)
              );
            }
            for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
              this.prodForm.prod_plmtrl_list[i].prod_parent_list = [];
              if (
                this.prodForm.prod_plmtrl_list[i].prod_parent_ids.indexOf(
                  ","
                ) >= 0
              ) {
                let arr =
                  this.prodForm.prod_plmtrl_list[i].prod_parent_ids.split(",");
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.prodForm.prod_plmtrl_list[i].prod_parent_list = arr;
              } else {
                let arr =
                  this.prodForm.prod_plmtrl_list[i].prod_parent_ids.split(" ");
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.prodForm.prod_plmtrl_list[i].prod_parent_list = arr;
              }
              let item = {};
              item.prod_mtrl_id =
                this.prodForm.prod_plmtrl_list[i].prod_mtrl_id;
              item.mtrl_no = this.prodForm.prod_plmtrl_list[i].mtrl_no;
              this.plmtrlOption.push(item);
              this.cutOption.push(item);
              if (this.prodForm.prod_plmtrl_list[i].mtrl_prod_price !== null) {
                this.prodForm.prod_plmtrl_list[i].mtrl_prod_price =
                  this.helper.haveFour(
                    this.prodForm.prod_plmtrl_list[i].mtrl_prod_price
                  );
              }
              for (
                let j = i + 1;
                j < this.prodForm.prod_plmtrl_list.length;
                j++
              ) {
                this.prodForm.prod_plmtrl_list[j].plmtrlOption.push(
                  JSON.parse(JSON.stringify(item))
                );
              }
            }
            for (let i = 0; i < this.prodForm.prod_plmtrl_list.length; i++) {
              this.prodForm.prod_plmtrl_list[i].prod_parent_listO = [];
              for(let j=0;j<this.prodForm.prod_plmtrl_list[i].prod_parent_list.length;j++){
                let temp = this.prodForm.prod_plmtrl_list[i].plmtrlOption.filter(item=>{return item.prod_mtrl_id === this.prodForm.prod_plmtrl_list[i].prod_parent_list[j]})
                
                if(temp[0]){
                  temp[0].key = JSON.parse(JSON.stringify(j))
                  this.prodForm.prod_plmtrl_list[i].prod_parent_listO.push(JSON.parse(JSON.stringify(temp[0])))
                }
              }
            }
            for (let i = 0; i < this.prodForm.prod_cut_list.length; i++) {
              let item = {};
              item.prod_mtrl_id = this.prodForm.prod_cut_list[i].prod_mtrl_id;
              item.mtrl_no = JSON.parse(
                JSON.stringify(this.prodForm.prod_cut_list[i].mtrl_no)
              );
              this.semiprodOption.push(item);
              this.prodForm.prod_cut_list[i].prod_parent_value = Number(
                this.prodForm.prod_cut_list[i].prod_parent_ids
              );
              this.prodForm.prod_cut_list[i].cutOption = JSON.parse(
                JSON.stringify(this.cutOption)
              );
              this.prodForm.prod_cut_list[i].mtrl_no =
                this.prodForm.prod_cut_list[i].mtrl_no.substr(
                  this.prodForm.prod_cut_list[i].mtrl_no.indexOf("@") + 1,
                  this.prodForm.prod_cut_list[i].mtrl_no.length
                );
            }
            for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
              this.prodForm.prod_semiprod_list[i].semiprodOption = JSON.parse(
                JSON.stringify(this.semiprodOption)
              );
            }
            for (let i = 0; i < this.prodForm.prod_semiprod_list.length; i++) {
              this.prodForm.prod_semiprod_list[i].prod_parent_list = [];
              if (
                this.prodForm.prod_semiprod_list[i].prod_parent_ids.indexOf(
                  ","
                ) >= 0
              ) {
                let arr =
                  this.prodForm.prod_semiprod_list[i].prod_parent_ids.split(
                    ","
                  );
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.prodForm.prod_semiprod_list[i].prod_parent_list = arr;
              } else {
                let arr =
                  this.prodForm.prod_semiprod_list[i].prod_parent_ids.split(
                    " "
                  );
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.prodForm.prod_semiprod_list[i].prod_parent_list = arr;
              }
              let item = {};
              item.prod_mtrl_id =
                this.prodForm.prod_semiprod_list[i].prod_mtrl_id;
              item.mtrl_no = this.prodForm.prod_semiprod_list[i].mtrl_no;
              for (
                let j = i + 1;
                j < this.prodForm.prod_semiprod_list.length;
                j++
              ) {
                this.prodForm.prod_semiprod_list[j].semiprodOption.push(
                  JSON.parse(JSON.stringify(item))
                );
              }
              this.semiprodOption.push(JSON.parse(JSON.stringify(item)));
              this.prodForm.prod_semiprod_list[i].mtrl_no =
                this.prodForm.prod_semiprod_list[i].mtrl_no.substr(
                  this.prodForm.prod_semiprod_list[i].mtrl_no.indexOf("#") + 1,
                  this.prodForm.prod_semiprod_list[i].mtrl_no.length
                );
            }
            this.getInputUser();
            setTimeout(() => {
              this.bomFlag = false;
              this.plmtrlFlag = false;
              this.cutFlag = false;
              this.semiprodFlag = false;
            }, 1000);
            setTimeout(() => {
              this.showPlmtrl = true;
              this.loadingFlag = false;
            }, 1000);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //获取录入人
    getInputUser() {
      this.stffForm.cptt_id = this.prodForm.cptt_id;
      this.stffForm.user_id = this.prodForm.user_id;
      this.stffForm.dept_id = this.prodForm.dept_id;
      this.stffForm.stff_id = this.prodForm.stff_id;
      this.stffForm.cptt_abbr = this.prodForm.cptt_abbr;
      this.stffForm.dept_name = this.prodForm.dept_name;
      this.stffForm.dept_team_id = this.prodForm.dept_team_id;
      this.stffForm.dept_team_name = this.prodForm.dept_team_name;
      this.stffForm.stff_name = this.prodForm.stff_name;
    },
  },
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
.vd_buttom {
  padding-bottom: 32px;
}
.vd_but {
  margin-bottom: 10px;
}
.vd_ml10 {
  margin-left: 10px;
}
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_edhea {
  position: relative;
  margin-bottom: 20px;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_export1{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_exports {
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_mrb {
  margin-bottom: 1px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_cussor {
  cursor: default;
}
.vd_draw{
  padding: 20% 10%;
}
::v-deep .el-upload--text{
  border: 1px dashed #d9d9d9 !important;
}
</style>
