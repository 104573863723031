<template>
  <el-table :data="prodForm.prod_semiprod_list" border @selection-change="handleSelectionChange1" class="el-table-two">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template slot-scope="scope">
        {{ scope.$index+1 }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">所用物料</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_semiprod_list.'+scope.$index+'.prod_parent_ids'" :rules="[{required:true}]">
          <el-select @change="semiprodChange(scope)" v-if="flag" v-model="prodForm.prod_semiprod_list[scope.$index].prod_parent_list" multiple placeholder="请选择">
            <el-option
              v-for="item in prodForm.prod_semiprod_list[scope.$index].semiprodOption"
              :key="item.prod_mtrl_id"
              :label="item.mtrl_no"
              :value="item.prod_mtrl_id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料编号</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_semiprod_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
          <el-input @change="mtrlNoChange(scope)" v-model="prodForm.prod_semiprod_list[scope.$index].mtrl_no" @input ="prodForm.prod_semiprod_list[scope.$index].mtrl_no = helper.keepEngNum2T(prodForm.prod_semiprod_list[scope.$index].mtrl_no)" maxlength="30" show-word-limit placeholder="暂无物料编号">
            <template slot="prepend" class="vd_prodNo">{{prodForm.prod_no}}#</template>
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料名称</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_semiprod_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
          <el-input v-model="prodForm.prod_semiprod_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <!-- <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">工艺信息</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_semiprod_list.'+scope.$index+'.mtrl_spec'" :rules="[{required:true}]">
          <el-input type="textarea" v-model="prodForm.prod_semiprod_list[scope.$index].mtrl_spec" maxlength="50" show-word-limit placeholder="暂无工艺信息"></el-input>
          <el-select v-model="prodForm.prod_semiprod_list[scope.$index].prod_parent_list" multiple placeholder="请选择">
            <el-option
              v-for="item in crtfTypeGroupOpt"
              :key="item.id"
              :label="item.param1"
              :value="item.param1">
            </el-option>
          </el-select>
        </el-form-item> 
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
import {get,post} from "@api/request";
import {optnAPI} from "@api/modules/optn";
export default {
  name:"ProdEditPrcsChild",
  props:{
    prodForm:{
      type: Object,
      required:true,
    },
  },
  data(){
    return {
      selectionsList:[],
      crtfTypeGroupOpt:[],
      flag:true,
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      // this.getCrtfType()
    },
    // 获取单位
    getCrtfType(){
      get(optnAPI.getAllContent,{perm_id : 10002})
      .then(res=>{
       
        if(res.data.code === 0) {
          this.crtfTypeGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 物料编号重复
    mtrlNoChange(scope){
      let mtrlFlag = 0;
      for(let i=0;i<this.prodForm.prod_semiprod_list.length;i++){
        if(this.prodForm.prod_semiprod_list[i].mtrl_no === scope.row.mtrl_no){
          mtrlFlag += 1
        }
      }
      if(mtrlFlag>1){
        scope.row.mtrl_no = null
        this.$message.error('物料编号重复，请重新填写！')
      }else{
          this.$emit("semiprodChangeCut",scope)
        }
    },
    // 下拉框回显
    semiprodChange(scope){
      this.flag = false;
      this.$emit("semiprodChangeIds",scope)
      this.$nextTick(()=>{
        this.flag=true;
      })
    },
    //prcs清单选择框
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit("handleSelectChangeSemiprod",this.selectionsList);
    },
  }
}
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl{
  margin-left: 5px;
}
.vd_wid100{
  width: 100%;
}
.el-input-group__prepend{
  font-size: 16px;
  color: #000000;
}
</style>