<template>
  <el-table :data="prodForm.prod_cut_list" border @selection-change="handleSelectionChange1" class="el-table-two">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template slot-scope="scope">
        {{ scope.$index+1 }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">所用物料</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_cut_list.'+scope.$index+'.prod_parent_value'" :rules="[{required:true}]">
          <!-- <el-input show-word-limit maxlength="30" v-model="prodForm.prod_cut_list[scope.$index].prod_parent_ids" placeholder="暂无所用物料"></el-input> -->
          <el-select @change="cutChange(scope)" v-if="flag" v-model="prodForm.prod_cut_list[scope.$index].prod_parent_value" placeholder="请选择">
            <el-option
              v-for="item in prodForm.prod_cut_list[scope.$index].cutOption"
              :key="item.prod_mtrl_id"
              :label="item.mtrl_no"
              :value="item.prod_mtrl_id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料编号</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_cut_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
          <el-input @change="mtrlNoChange(scope)" v-model="prodForm.prod_cut_list[scope.$index].mtrl_no" @input ="prodForm.prod_cut_list[scope.$index].mtrl_no = helper.keepEngNum2T(prodForm.prod_cut_list[scope.$index].mtrl_no)" maxlength="30" show-word-limit placeholder="暂无物料编号">
            <template slot="prepend" class="vd_prodNo">{{prodForm.prod_no}}@</template>
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料名称</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'prod_cut_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
          <el-input v-model="prodForm.prod_cut_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name:"ProdEditPrcsChild",
  props:{
    prodForm:{
      type: Object,
      required:true,
    },
  },
  data(){
    return {
      selectionsList:[],
      mtrlTypeGroupOpt:[],
      flag:true,
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
    },
    // 物料编号重复
    mtrlNoChange(scope){
      let mtrlFlag = 0;
      for(let i=0;i<this.prodForm.prod_cut_list.length;i++){
        if(this.prodForm.prod_cut_list[i].mtrl_no === scope.row.mtrl_no){
          mtrlFlag += 1
        }
      }
      if(mtrlFlag>1){
        scope.row.mtrl_no = null
        this.$message.error('物料编号重复，请重新填写！')
      }else{
        this.$emit("cutChangeCuts",scope)
      }
    },
    // 下拉框回显
    cutChange(scope){
      this.flag = false;
      this.$emit("cutChangeIds",scope)
      this.$nextTick(()=>{
        this.flag=true;
      })
    },
    //prcs清单选择框
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit("handleSelectionChange1",this.selectionsList);
    },
  }
}
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl{
  margin-left: 5px;
}
.vd_wid100{
  width: 100%;
}
.el-input-group__prepend{
  font-size: 16px;
  color: #000000;
}
</style>