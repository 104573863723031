<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho" @draftState="draftState"/>
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" :lazy="true">
        <childTab3/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProdEditMain from "@/views/DevelopManagement/ProdManage/TabChild/ProdEditMain";
import ProdEditAppr from "@/views/DevelopManagement/ProdManage/TabChild/ProdEditAppr";
import ProdEditAbout from "@/views/DevelopManagement/ProdManage/TabChild/ProdEditAbout";
export default {
  name: 'AcctEdit',
  components: {
    childTab1: ProdEditMain,
    childTab2: ProdEditAppr,
    childTab3: ProdEditAbout,
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  created() {},
  methods: {
    isShow(val){
      this.isSho = val;
    },
    draftState(){
      this.$refs.childTab1.initData()
    },
  }
};
</script>

<style scoped lang="scss">

</style>