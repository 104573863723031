<template>
 <div>
   <appr-main :isShow="isShow" @draftState="draftState"></appr-main>
 </div>
</template>

<script>
import apprMain from "@/views/component/apprCommon/apprMain";
export default {
name: "ProdEditAppr",
components: {
    apprMain
  },
  props:{
    isShow:{
      type:Boolean,
      required:true,
    }
  },
  methods:{
    draftState(){
      this.$emit('draftState')
    }
  }
}
</script>

<style scoped>

</style>