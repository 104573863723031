<template>
  <div>
    <el-table
      :data="prodForm.prod_mtrl_list"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="48"
        align="center"
      ></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="物料编号" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_no'"
            :rules="[{ required: true }]"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_no"
              placement="top"
            >
              <el-input
                :disabled="true"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_no"
                maxlength="10"
                show-word-limit
                placeholder="暂无物料编号"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_name'"
            :rules="[{ required: true }]"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_name"
              placement="top"
            >
              <el-input
                :disabled="true"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_name"
                maxlength="30"
                show-word-limit
                placeholder="暂无物料名称"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料类型" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_type'"
            :rules="[{ required: true }]"
          >
            <el-input
              :disabled="true"
              v-if="prodForm.prod_mtrl_list[scope.$index].mtrl_type === 0"
              v-model="mtrlType1"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="prodForm.prod_mtrl_list[scope.$index].mtrl_type === 1"
              v-model="mtrlType2"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="prodForm.prod_mtrl_list[scope.$index].mtrl_type === 2"
              v-model="mtrlType3"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格" show-word-limit show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_spec'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_spec"
              placement="top"
            >
              <el-input
                :disabled="true"
                maxlength="50"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_spec"
                placeholder="暂无规格"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="潘通色号" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_color'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_color"
              placement="top"
            >
              <el-input
                :disabled="true"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_color"
                maxlength="20"
                show-word-limit
                placeholder="暂无颜色"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="克重" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_weight'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_weight + ''"
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="8"
                @blur="
                  prodForm.prod_mtrl_list[scope.$index].mtrl_weight =
                    helper.retain(
                      prodForm.prod_mtrl_list[scope.$index].mtrl_weight,
                      2
                    )
                "
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_weight"
                placeholder="暂无克重"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="门幅" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_width'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_width + ''"
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                @blur="
                  prodForm.prod_mtrl_list[scope.$index].mtrl_width =
                    helper.retain(
                      prodForm.prod_mtrl_list[scope.$index].mtrl_width,
                      2
                    )
                "
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_width"
                placeholder="暂无门幅"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="毛高(毫米)" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_thick'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="prodForm.prod_mtrl_list[scope.$index].mtrl_thick + ''"
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_thick"
                placeholder="暂无毛高"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column label="库位" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtqr_bin_list'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                prodForm.prod_mtrl_list[scope.$index].mtqr_bin_list
                  ? prodForm.prod_mtrl_list[scope.$index].mtqr_bin_list + ''
                  : ''
              "
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtqr_bin_list"
                placeholder="暂无库位"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="库存数" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mstk_num'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                prodForm.prod_mtrl_list[scope.$index].mstk_num
                  ? prodForm.prod_mtrl_list[scope.$index].mstk_num + ''
                  : ''
              "
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="prodForm.prod_mtrl_list[scope.$index].mstk_num"
                placeholder="暂无库存数"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_price'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                prodForm.prod_mtrl_list[scope.$index].mtrl_price
                  ? prodForm.prod_mtrl_list[scope.$index].mtrl_price + ''
                  : ''
              "
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_price"
                placeholder="暂无单价"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column width="200px">
        <template slot="header">
          <span >剪料长宽</span>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.prod_mtrl_len_wid">
            <el-tag
              class="elTagItem"
              v-for="(item, index) in scope.row.prod_mtrl_len_wid.split(',')"
              :key="index"
              :closable="scope.row.prod_mtrl_status == 1 ? false : !isShow"
              @close="handleClose(scope.$index, index)"
              :disabled="isShow"
              >{{ item }}</el-tag
            >
          </span>
          <el-button
            plian
            class="el-icon-plus vg_pointer circle" plain
            @click="addTag(scope.$index, index)"
            :disabled='scope.row.prod_mtrl_status == 1 ? true :false'
          ></el-button>
        </template>
      </el-table-column> -->
      <!-- <el-table-column show-overflow-tooltip>
        <template slot="header">
          <span>剪料单位</span>
        </template>
        <template>
          <el-input size="mini" :disabled="true" value="厘米"></el-input>
        </template>
      </el-table-column> -->
      <el-table-column show-overflow-tooltip label="耗料">
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'prod_mtrl_list.' + scope.$index + '.prod_mtrl_consume'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume
                  ? prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume + ''
                  : ''
              "
              placement="top-start"
            >
              <el-input
              :disabled="!specialDataFlag"
                show-word-limit
                maxlength="9"
                v-model="
                  prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume
                "
                @input="
                  prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume =
                    helper.keepTNum1(
                      prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume
                    )
                "
                @blur="
                  prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume =
                    helper.retain1(
                      prodForm.prod_mtrl_list[scope.$index].prod_mtrl_consume,
                      2,
                      1000000
                    )
                "
                placeholder="暂无耗料"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-form-item
            label-width="1000"
            :prop="'prod_mtrl_list.' + scope.$index + '.mtrl_unit'"
            :rules="[{ required: true }]"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                prodForm.prod_mtrl_list[scope.$index].mtrl_unit
                  ? prodForm.prod_mtrl_list[scope.$index].mtrl_unit + ''
                  : ''
              "
              placement="top-start"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="9"
                v-model="prodForm.prod_mtrl_list[scope.$index].mtrl_unit"
                placeholder="暂无耗料"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center">
        <template slot="header">
          <span>状态</span>
        </template>
        <template slot-scope="scope">
          <el-tag v-if="!prodForm.prod_mtrl_list[scope.$index].prod_mtrl_status"
            >未出库</el-tag
          >
          <div v-else>
            <el-tag v-if="scope.row.prod_mtrl_status == 0">未出库</el-tag>
            <el-tag v-if="scope.row.prod_mtrl_status == 1" type="success"
              >已出库{{scope.row.prod_mtrl_status}}次</el-tag
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="提示:请输入物料长度和物料宽度"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handelClose"
    >
    <el-row>
        <el-form>
      <el-col :md="11">
      
          <el-form-item label="长：" label-width="60px">
         <el-input
      maxlength="10"
      show-word-limit
        v-model="value"
        @input="value = helper.keepTNum1(value)"
        @change="value = helper.retain1(value,2,10000)"
      >
      <template slot="append">厘米</template>
      </el-input>
      </el-form-item>
       
      </el-col>
      <el-col :md="2" class="vd_center">*</el-col>
      <el-col :md="11">
        <el-form-item label="宽：" label-width="60px">
           <el-input
      maxlength="10"
      show-word-limit
        v-model="value2"
        @input="value2 = helper.keepTNum1(value2)"
        @change="value2 = helper.retain1(value2,2,10000)"
      >
      <template slot="append">厘米</template>
      </el-input>
      </el-form-item>
      </el-col>
       </el-form>
    </el-row>
     
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="detClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ProdEditBomChild",
  props: {
    prodForm: {
      type: Object,
      required: true,
    },
    mtrlTypeGroupOpt: {
      type: Array,
      required: true,
    },
    isShow: {
      type: Boolean,
      required:true
    },
    specialDataFlag: {
      type: Boolean,
      required:true
    },
  },
  data() {
    return {
      selectionsList: [],
      mtrlType: [
        { id: 0, label: "原面料" },
        { id: 1, label: "辅料" },
        { id: 2, label: "包材" },
      ],
      mtrlType1: "原面料",
      mtrlType2: "辅料",
      mtrlType3: "包材",
      dialogVisible: false,
      rowIndex: null,
      index: "",
      value: "", //新增剪料 第一个输入框
      value2:"" //新增剪料第二个输入框
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit("handleSelectionChange", this.selectionsList);
    },
    handleClose(rowI, index) {  
      let arr = this.prodForm.prod_mtrl_list[rowI].prod_mtrl_len_wid.split(",");
      arr.splice(index, 1);
      this.prodForm.prod_mtrl_list[rowI].prod_mtrl_len_wid = arr.join(",");
    },
    addTag(rowIndex, index) {
      this.rowIndex = rowIndex;
      this.index = index;
     
      this.dialogVisible = true;
    },
    //关闭 dialog
    handelClose() {
      this.value = "";
      this.value2 = ''
      this.dialogVisible = false;
    },
    detClick() {
       if(this.value==''||this.value2==''){
         return  this.$message.warning('长和宽不可以为空')
       }
       if(this.value=='0.00'||this.value2=='0.00'){
          return  this.$message.warning('长和宽不可以为0')
       }
       if(!this.prodForm.prod_mtrl_list[this.rowIndex].prod_mtrl_len_wid){
        this.$set(this.prodForm.prod_mtrl_list[this.rowIndex],'prod_mtrl_len_wid','')
      }else{
        this.prodForm.prod_mtrl_list[this.rowIndex].prod_mtrl_len_wid+=','
      }
      this.$forceUpdate()
        let newValue = this.value + '*'+this.value2
      this.prodForm.prod_mtrl_list[this.rowIndex].prod_mtrl_len_wid += newValue;
      this.value = "";
      this.value2 = ''
      this.dialogVisible = false;
    },
   
  },
};
</script>

<style scoped>
 .circle {
   background-color:#c4bcbc ;
  border-radius: 50%;
  padding: 2px;
  border: 0;
  color: #fff;
}
.elTagItem {
  padding: 0 2px;
  margin-right: 2px;
  height: 24px;
  line-height: 24px;
}
.vd_center {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
}
</style>
