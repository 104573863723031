import topic from '../topic'
export const acctAPI = {
    getAccts: topic.acctTopic + '/get_accts',
    getAcctById: topic.acctTopic + '/get_acct_by_id',
    getAcctFctrs: topic.acctTopic + '/get_acct_fctrs',
    addAcct: topic.acctTopic + '/add_acct',
    deleteAcctByIds: topic.acctTopic + '/delete_acct_by_ids',
    editAcct: topic.acctTopic + '/edit_acct',
    getAllAcctsV1: topic.acctTopic + '/get_all_accts_v1',
    getMyAcct: topic.acctTopic + '/get_my_acct',
    editMyAcct: topic.acctTopic + '/edit_my_acct',
}