import topic from '../topic'
export const prodAPI = {
    getProds: topic.prodTopic + '/get_prods',
    // getProdsPpot: topic.prodTopic + '/get_prods_ppot',
    // getProdsPpin: topic.prodTopic + '/get_prods_ppin',
    getProdsV1: topic.prodTopic + '/get_prods_v1',
    getProdById: topic.prodTopic + '/get_prod_by_id',
    addProd: topic.prodTopic + '/add_prod',
    editProd: topic.prodTopic + '/edit_prod',
    destroyProdByIds: topic.prodTopic + '/destroy_prod_by_ids',
    prodShareAccts: topic.prodTopic + '/prod_share_accts',
}