import topic from '../topic';
import { getNoCatch } from '@api/request';

export const mtrlAPI = {
  getMtrls: topic.mtrlTopic + '/get_mtrls',
  getMtrlsInclusive: topic.mtrlTopic + '/get_mtrls_inclusive',
  getMtrlById: topic.mtrlTopic + '/get_mtrl_by_id',
  addMtrl: topic.mtrlTopic + '/add_mtrl',
  editMtrl: topic.mtrlTopic + '/edit_mtrl',
  deleteMtrlByIds: topic.mtrlTopic + '/delete_mtrl_by_ids',
  judgeMtrlNo: topic.mtrlTopic + '/judge_mtrl_no',
  mtrlShareAccts: topic.mtrlTopic + '/mtrl_share_accts',
  getDyjMtrl: params => getNoCatch(`${topic.mtrlTopic}/getDyjMtrl`, params)
};
