<template>
   <div class="vg_button_group">
      <span v-if="isShow === true">
        <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
      </span>
      <span v-if="isShow === false">
        <el-button type="primary" @click="submit(strForm)" size="small">保存</el-button>
        <el-button  @click="closeEdit" size="small">取消</el-button>
      </span>
      <!-- <span class="vg_tag_position" v-if="stateFlag"> -->
      <span class="vg_tag_position" v-if="statusShow">
         <span class="vg_tag_label">状态：</span>
         <el-tag :type="helper.getStatusName(strForm.status).type">{{helper.getStatusName(strForm.status).name}}</el-tag>
      </span>
   </div>
</template>

<script>
export default {
  name: "editHeader",
  props:{
    isShow: Boolean,
    btn:Object,
    strForm:Object,
    statusShow:{
      type:Boolean,
      default:false
    },
  },
  data(){
    return{
      stateFlag:true
    }
  },
  methods:{
    openEdit(){
      this.$emit('openEdit')
    },
    closeEdit(){
      this.$emit('closeEdit')
    },
    submit(strForm){
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>